<template lang="html">
  <a :href="'#' + $route.path"
  class="component-link"
  @click="goToTroubleshootingArticle(article.id, $event)">

    <!-- <div class="image-column">
      <icon :graphic="article.icon"></icon>
    </div> -->

    <div class="text-column">
      <div class="inner">
        <span class="item-number" v-if="blockLayout === 'image_menu'">{{ itemNumber }}</span>
        {{ _(article.title) | capitalise }}
      </div>
      <div class="item-icon">
        <icon graphic="chevron"></icon>
      </div>
    </div>
  </a>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'troubleshootingLink',
  props: {
    articleID: String,
    blockLayout: String,
    itemNumber: Number,
  },
  mixins: [TranslationMixin],
  computed: {
    article: function() {
      return this.$store.getters.getTroubleshootingByID(this.articleID)
    }
  },
  methods: {
    goToTroubleshootingArticle: function(id, event) {
      event.preventDefault();
      console.log('troubleshooting link clicked');
      this.$emit('go-to-troubleshooting', id);
    },
  }
}
</script>

<style lang="scss" scoped>
  .component-link {
    .text-column {
      .inner {
        padding: 2rem;
      }
    }
  }
</style>
