<template>
  <main class="view course">
    <div class="view-inner">
      <!-- <router-link :to="{ name: 'Product', params: { id: activeVariant.id } }">{{ _('home') | capitalise }}</router-link> -->
      <course-menu :lessonIDs="activeCourse.lessons" :quizIDs="activeCourse.quizes"></course-menu>
      <!-- <h2>{{ _('lesson_plural') | capitalise }}</h2> -->

      <section class="course-content" :class="{ '-menu-open': courseMenuOpen }" @click="closeCourseMenu">
        <!-- <div v-if="courseMode === 'lesson' && !activeLesson">
          <h2>{{ _(activeVariant.title) }}</h2>
          <p class="description">{{ _(course.description) }}</p>
          <button type="button" name="startCourseButton" @click="startCourse">{{ _('start_course') | capitalise }}</button>
        </div>

        <lesson v-if="courseMode === 'lesson' && activeLesson" :lessonID="activeLesson.id"></lesson>
        <quiz v-if="courseMode === 'quiz'"></quiz> -->
        <!-- <transition :name="subviewTransitionName"> -->
          <router-view/>
        <!-- </transition> -->
      </section>

    </div>
  </main>
</template>

<style lang="scss">
.view.course {
  --course-menu-width: 25rem;
  --closed-menu-displacement: 0;

  @include media-md {
    --closed-menu-displacement: 5rem;
  }

  >.view-inner {
    padding: 0;
    overflow-x: hidden;
    max-width: 100vw;

    @include media-md {
      flex-direction: row;
    }
  }
}

.course-menu {
  position: absolute;
  z-index: 30;
  left: 0;
  padding: 0;
  // top: 0;
  // transform: translateY(var(--navbar-height));
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  // overflow: hidden;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;

  @include media-sm {
    max-width: var(--course-menu-width);
  }

  @include media-md {
    position: relative;
    flex: none;
  }
}

.course-content {
  flex: auto;
  z-index: 35;
  position: relative;
  background-color: var(--color-white);
  // box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
  box-shadow: 2rem 0 2rem 2rem rgba(0,0,0,.4);
  // width: calc(100vw - var(--closed-menu-displacement));
  width: calc(100% - var(--closed-menu-displacement));
  // min-height: calc(100vh - var(--navbar-height));
  padding: var(--gutter-width);
  // background-image: linear-gradient(90deg, rgba(0,0,0,0.00) 95%, rgba(0,0,0,0.04) 99%, rgba(0,0,0,0.06) 100%);
  transition: transform .3s ease 0s;
  will-change: transform;
  transform: translateX(var(--closed-menu-displacement));
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-md {
    flex: none;
    width: calc(100% - var(--closed-menu-displacement));
    transform: translateX(calc(-1 * (var(--course-menu-width) - var(--closed-menu-displacement))));
  }

  // &::before {
  //   content: '';
  //   position: fixed;
  //   left: 0;
  //   width: 100vw;
  //   height: 100vh;
  //   background-color: var(--color-white);
  //   top: 0;
  //   transform: translateX(var(--closed-menu-displacement));
  //   transition: transform .3s ease 0s;
  //   // transform: translate3d(-100%,0,0);
  // }

  &.-menu-open {
    transform: translateX(var(--course-menu-width));

    // &::before {
    //   transform: translateX(var(--course-menu-width));
    // }
    @include media-md {
      transform: translateX(0);
    }
  }

  // &::after {
  //   bottom: 0;
  //   transform: translate3d(100%,0,0);
  // }

  @include media-sm {
    --gutter-width: 3rem;
  }

  .lesson,
  .quiz {
    @include media-xl {
      max-width: 80%;
    }
  }
}
</style>

<script>
import TranslationMixin from '@/mixins/translation.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'
import CourseMenu from '@/components/CourseMenu.vue'

export default {
  name: 'Course',
  data: function() {
    return {
      transitionName: 'slide',
    }
  },
  mixins: [TranslationMixin, ActiveItemsMixin],
  components: { CourseMenu },
  computed: {
    courseMode: function() {
      console.log(this.$store.getters.getCourseMode);
      return this.$store.getters.getCourseMode
    },
    courseMenuOpen: function() {
      return this.$store.getters.getCourseMenuOpen;
    },
    subviewTransitionName: function() {
      return this.$store.getters.getCourseSubviewTransitionName
    },
  },
  methods: {
    closeCourseMenu: function(e) {
      this.$store.dispatch('setCourseMenuOpen', false)
    },
    setTransitionName: function(msg) {
      console.log('set transition name', msg);
      this.transitionName = msg;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('setMetaThemeColor', 'course');
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('setMetaThemeColor', 'default');
    next()
  }
}
</script>
