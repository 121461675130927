<template lang="html">
  <section class="quiz-review">
    <!-- <section class="task" v-for="(task, index) in tasks" :key="task.id">

      <h3 class="title">{{ _('task') }} {{ index }}/{{ Object.keys(tasks).length }} {{ _(task.task.problem.title) | capitalise }}</h3>

      <section class="task-options">
        <label v-for="(option, index) in task.task.options"
        :key="'task_' + task.id + 'option_' + index"
        class="option"
        :class="{ '-selected': index === task.answer, '-correct': option.is_correct }">
          <input class="radio-btn"
          type="radio"
          :key="'task_' + task.id + '_radio_' + index"
          :id="'task_' + task.id + '_' + index"
          :name="'task_' + task.id + '_radio'"
          :value="index"
          :checked="index === task.answer">
          <span>{{ _(option.text) }}</span>
        </label>
      </section>

    </section> -->

    <section class="task" v-for="task in tasks" :key="task.id">
      <h3 class="title">
        <span class="counter">{{ _('task') | capitalise }} {{ Object.keys(tasks).indexOf(task.id) + 1 }}/{{ Object.keys(tasks).length }} –</span>
        {{ _(task.task.problem.title) | capitalise }}
      </h3>

      <section class="task-options">
        <div v-for="(option, index) in task.task.options"
        :key="'task_' + task.id + 'option_' + index"
        class="option"
        :class="{ '-selected': index === task.answer, '-correct': option.is_correct }">

          <h4 class="sr-only">{{ _('option') }} {{ index + 1 }}</h4>

          <i class="radio-btn"
          type="radio"
          :key="'task_' + task.id + '_radio_' + index"
          :id="'task_' + task.id + '_' + index"
          :name="'task_' + task.id + '_radio'"
          :value="index"
          :class="{ '-checked': index === task.answer }"></i>

          <span class="option-text">{{ _(option.text) }}</span>

          <dl class="sr-only">
            <dt>{{ _('answer_selected') }}</dt>
            <dd v-if="index === task.answer">true</dd>
            <dd v-else>false</dd>

            <dt>{{ _('answer_correct') }}</dt>
            <dd v-if="option.is_correct">true</dd>
            <dd v-else>false</dd>
          </dl>

        </div>
      </section>

    </section>

  </section>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'QuizReview',
  mixins: [TranslationMixin],
  computed: {
    tasks: function() {
      return this.$store.getters.getActiveQuizTasks
    }
  }
}
</script>

<style lang="scss" scoped>
.quiz-review {
  display: grid;
  grid-gap: var(--gutter-width);
}
.task-options {
  display: grid;
  grid-gap: 1rem;
  .option {
    // height: 0;
    // padding-bottom: 75%;
    border-radius: .6rem;
    padding: 1rem;
    position: relative;
    background-color: #fafafa;
    border: .1rem solid var(--color-border-grey);
    display: flex;
    align-items: center;
    .option-text {
      margin-top: .4em;
    }
    .radio-btn {
      @include custom-input-style;
      @include checkbox-base-style;
      @include custom-radio-style;
      &.-checked {
        &:after {
          transform: scale(1);
        }
      }
      margin-right: 1rem;
      @include media-sm {
        margin-right: 2rem;
      }
    }
    &.-selected,
    &.-correct {
      border-width: .2rem;
    }
    &.-selected {
      border-color: var(--color-yellow-1);
      background-color: var(--color-pale-yellow);
      --color-background: var(--color-yellow-1);
      --color-foreground: var(--color-white);
      .radio-btn {
        border-color: inherit;
      }
      &:not(.-correct) {
        border-color: var(--color-red-1);
        background-color: var(--color-pale-red);
        .radio-btn {
          border-color: var(--color-yellow-1);
        }
      }
    }
    &.-correct {
      &:not(.-selected) {
        border-color: var(--color-yellow-1);
        // background-color: var(--color-pale-red);
      }
    }
  }
}
</style>
