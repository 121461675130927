export default {
  computed: {
    tasks: function() {
      return this.$store.getters.getActiveQuizTasks
    },
    // activeTask: function() {
    //   return this.$store.getters.getActiveTask
    // },
    taskIndex: function() {
      if (this.tasks) {
        return Object.keys(this.tasks)
      }
      return false
    },
    totalTaskCount: function() {
      return this.taskIndex.length
    },
    currentIndex: function() {
      if (this.activeTask) {
        return this.taskIndex.indexOf(this.activeTask.id)
      }
      return null
    },
    quizProgress: function() {
      var courseProgress = this.$store.getters.getCourseProgress(this.activeCourse.id);
      if (courseProgress) {
        if (courseProgress.quizes) {
          if (courseProgress.quizes[this.activeQuiz.id]) {
            return courseProgress.quizes[this.activeQuiz.id];
          }
        }
      }
      return false
    },
    showQuizResults: function() {
      return this.$store.getters.getQuizResultsBool
    },
    showQuizReview: function() {
      return this.$store.getters.getQuizReviewBool
    },
  }
}
