<template>
  <section class="content-section quiz">
    <header class="quiz-header">
      <div class="title-row">
        <icon graphic="quiz"></icon>
        <h2 class="title">{{ _(activeVariant.title) | capitalise }} – {{ _(activeQuiz.title) | capitalise }}</h2>
      </div>
      <p v-if="activeTask && activeTask.task && activeTask.task.problem && !showQuizResults">
        {{ _('task') | capitalise }} {{ currentTaskNumber }}/{{ totalTaskCount }} – {{ _(activeTask.task.problem.title) | capitalise }}
      </p>
      <p v-else-if="!showQuizResults">{{ _('start_quiz') }}</p>
      <p v-if="showQuizResults">{{ _('quiz_results') | capitalise }}</p>
    </header>

    <!-- <div v-if="isAdmin">
      {{ activeTask }}
    </div> -->

    <section class="quiz-main">

      <section class="quiz-tasks" v-if="showQuizTasks">
        <transition :name="taskTransitionName">
          <div class="active-task" :key="'activeTask_' + currentTaskNumber">
            <task></task>
            <quiz-nav></quiz-nav>
          </div>
        </transition>
      </section>

      <section class="quiz-summary" v-else-if="showQuizResults && quizProgress">
        <div class="image-container">
          <icon v-if="quizProgress.correct_answers === totalTaskCount" graphic="quiz-excellent"></icon>
          <icon v-else-if="quizProgress.passed" graphic="quiz-good"></icon>
          <icon v-else graphic="quiz-fail"></icon>
        </div>

        <h3 class="title">{{ summaryTitle | capitalise }}</h3>
        <p class="task-tally">{{ quizProgress.correct_answers }}/{{ totalTaskCount }} correct</p>

        <quiz-summary-nav></quiz-summary-nav>

        <quiz-review v-if="showQuizReview"></quiz-review>
      </section>

      <div class="quiz-overview" v-if="!activeTask && !showQuizResults">
        <div class="image-container">
          <icon graphic="quiz-overview"></icon>
        </div>
        <p class="lead">{{ _(activeQuiz.description) }}</p>
        <button type="button" class="course-button" name="startCourseQuiz" @click="startQuiz"><span>{{ _('start_quiz') | capitalise }}</span></button>
      </div>

    </section>

  </section>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import CourseNavMixin from '@/mixins/courseNavigation.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'
import QuizCommonsMixin from '@/mixins/quizCommons.js'

import Task from '@/components/Task.vue'
import QuizNav from '@/components/QuizNav.vue'
import QuizSummaryNav from '@/components/QuizSummaryNav.vue'
import QuizReview from '@/components/QuizReview.vue'

export default {
  name: 'Quiz',
  data: function() {
    return {
      // showQuizResults: false,
      // currentTaskNumber: 0,
    }
  },
  mixins: [TranslationMixin, CourseNavMixin, QuizCommonsMixin, ActiveItemsMixin],
  components: { Task, QuizNav, QuizSummaryNav, QuizReview },
  computed: {
    isAdmin: function() {
      return this.$store.getters.getUserIsAdmin
    },
    currentTaskNumber: function() {
      if (this.currentIndex !== null) {
        return this.currentIndex + 1
      }
      return null
    },
    subviewTransitionName: function() {
      return this.$store.getters.getCourseSubviewTransitionName
    },
    taskTransitionName: function() {
      return this.$store.getters.getCourseSubviewTransitionName
    },
    showQuizTasks: function() {
      if (this.activeTask && !this.showQuizResults) {
        return true
      } else {
        return false
      }
    },
    summaryTitle: function() {
      var sTheString = '';
      if (this.quizProgress.correct_answers === this.totalTaskCount) {
        sTheString = 'quiz_excellent';
      } else if (this.quizProgress.passed) {
        sTheString = 'quiz_passed';
      } else {
        sTheString = 'quiz_fail';
      }
      return this._(sTheString);
    },
    // showQuizResults: function() {
    //   return this.$store.getters.getQuizResultsBool
    // },
    // showQuizReview: function() {
    //   return this.$store.getters.getQuizReviewBool
    // },
    // quizProgress: function() {
    //   var courseProgress = this.$store.getters.getCourseProgress(this.activeCourse.id);
    //   if (courseProgress) {
    //     if (courseProgress.quizes) {
    //       if (courseProgress.quizes[this.activeQuiz.id]) {
    //         return courseProgress.quizes[this.activeQuiz.id];
    //       }
    //     }
    //   }
    //   return false
    // }
  },
  methods: {
    startQuiz: function() {
      this.navigateToTask(Object.keys(this.tasks)[0]);
      // this.$store.dispatch('setActiveTask', Object.keys(this.tasks)[0])
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('setQuizResultsBool', false);
    next();
  }
}
</script>

<style lang="scss" scoped>
.content-section.quiz {
  width: 100%;
  color: var(--color-blue-1);
  .quiz-tasks {
    // display: grid;
    // grid-gap: 4rem;
    // @include media-sm {
    //   grid-gap: 7.5rem;
    // }
  }
  .quiz-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    @include media-sm {
      margin-bottom: 4rem;
    }
    .title-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    >>> .icon {
      flex: none;
      margin-right: 2rem;
      .svg-fill {
        fill: var(--color-white);
      }
    }
    .title {
      margin: 0 2rem 0 0;
    }
  }
  .quiz-main {
    > .quiz-overview {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .lead,
      .image-container {
        flex: 1 0 100%;
      }
    }
    .image-container {
      display: flex;
      justify-content: center;
      padding: 4rem 0;
      @include media-sm {
        padding: 7rem 0;
      }
      .icon {
        --icon-width: 10rem;
        @include media-sm {
          --icon-width: 15.5rem;
        }
      }
    }
  }
  .quiz-summary {
    .title,
    .task-tally {
      margin: 0;
    }
  }
  >>> .content-nav {
    // display: grid;
    // grid-template-columns: repeat(3, minmax(12rem, 1fr));
    // grid-gap: 1rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    @include media-sm {
      margin-top: 7.5rem;
      margin-bottom: 7.5rem;
    }
  }
  >>> .quiz-review {
    margin-top: 6rem;
    @include media-sm {
      margin-top: 10rem;
    }
  }
  >>> .info-list {
    display: flex;
  }
}
</style>
