<template lang="html">
  <section class="task" :key="'task_' + taskObject.id">

    <!-- <select :key="'select_' + taskObject.id" v-if="task.type === 'multiple_choice'" class="task-select" name="" @change="updateQuizAnswers">
      <option value="Select option">Select option</option>
      <option v-for="(option, index) in task.options" :key="'task_' + task.id + '_option_' + index">{{ _(option.text) }}</option>
    </select> -->

    <!-- <div class="multiple-choice-grid" v-if="task.type === 'multiple_choice'">
      <div class="option" :class="{ '-selected': index === answer }" v-for="(option, index) in task.options" :key="'task_' + taskObject.id + 'option_' + index">
        <input class="radio-btn"
        type="radio"
        :key="'task_' + taskObject.id + '_radio_' + index"
        :id="'task_' + taskObject.id + '_' + index"
        :name="'task_' + taskObject.id + '_radio'"
        :value="index"
        :checked="index === answer" @change="updateQuizAnswer(index)">
        <label :for="'task_' + taskObject.id + '_' + index">{{ _(option.text) }}</label>
      </div>
    </div> -->

    <!-- <pre v-if="isAdmin">
      {{ task }}
    </pre> -->

    <section class="task-problem">
      <div class="task-image-grid" v-if="task.layout === 'image'">
        <div class="grid-image"
        v-for="(option, index) in task.options"
        :key="'grid_img_' + index"
        :class="{ '-selected': index === answer }">
          <image-component :url="option.image.url" :alt-attr="option.image.alt"></image-component>
          <div class="image-label">{{ _(option.text) }}</div>
        </div>
      </div>

      <div class="task-image" v-if="task.layout === 'text' && task.problem.image">
        <image-component :url="task.problem.image.url" :alt-attr="task.problem.image.alt"></image-component>
      </div>

      <!-- <div v-if="task.problem.description" class="task-description">
        {{ _(task.problem.description) | capitalise }}
      </div> -->
      <div v-if="task.problem.description && task.problem.description != 0" class="task-description" v-html="_(task.problem.description) | capitalise"></div>

    </section>

    <section class="task-options">
      <label class="option" :class="{ '-selected': index === answer }" v-for="(option, index) in task.options" :key="'task_' + taskObject.id + 'option_' + index">
        <input class="radio-btn"
        type="radio"
        :key="'task_' + taskObject.id + '_radio_' + index"
        :id="'task_' + taskObject.id + '_' + index"
        :name="'task_' + taskObject.id + '_radio'"
        :value="index"
        :checked="index === answer" @change="updateQuizAnswer(index)">
        <span>{{ _(option.text) }}</span>
      </label>
    </section>

  </section>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'Task',
  data: function() {
    return {
      selected: null,
    }
  },
  mixins: [TranslationMixin],
  computed: {
    isAdmin: function() {
      return this.$store.getters.getUserIsAdmin
    },
    quiz: function() {
      return this.$store.getters.getActiveQuiz
    },
    taskObject: function() {
      return this.$store.getters.getActiveTask
    },
    task: function() {
      return this.taskObject.task
    },
    answer: function() {
      return this.taskObject.answer
    },
  },
  methods: {
    updateQuizAnswer: function(ind) {
      this.$store.dispatch('updateQuizAnswer', { task: this.taskObject.id, answer: ind })
    },
    // updateQuizAnswers: function() {
    //   // console.log('ANSWER:', event.target.value);
    //   this.$store.dispatch('updateQuizAnswer', { task: this.taskObject.id, answer: event.target.value })
    //   // value: event.target.value
    // }
  },
  watch: {
    selected: function(ind) {
      this.$store.dispatch('updateQuizAnswer', { task: this.taskObject.id, answer: ind })
    }
  }
}
</script>

<style lang="scss" scoped>
.task {
  --gutter-width: 1rem;
  --border-radius: .4rem;
  // --border-width: .2rem;
  width: 100%;
  .task-problem {
    margin-bottom: 3rem;
    @include media-sm {
      margin-bottom: 6rem;
    }
    .task-image-grid {
      display: grid;
      grid-gap: var(--gutter-width);

      @include media-sm {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      .grid-image {
        position: relative;
        height: 0;
        padding-bottom: 140%;
        border-radius: var(--border-radius);
        background-color: var(--color-light-grey-3);
        overflow: hidden;
        border: .2rem solid transparent;

        &.-selected {
          border: .2rem solid var(--color-yellow-1);
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .image-label {
          position: absolute;
          top: 0;
          left: 0;
          margin: 1.5rem 0 0 1rem;
          color: var(--color-black);
          text-transform: uppercase;
        }
      }
    }
    .task-image {
      height: 0;
      padding-bottom: 55%;
      border-radius: var(--border-radius);
      overflow: hidden;
      background-color: var(--color-light-grey-3);
    }
    .task-image-grid,
    .task-image {
      position: relative;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &+.task-description {
        margin-top: 2rem;
        @include media-sm {
          margin-top: 4rem;
        }
      }
    }
  }
  .task-options {
    display: grid;
    grid-gap: var(--gutter-width);
    .option {
      // height: 0;
      // padding-bottom: 75%;
      border-radius: .6rem;
      padding: 1rem;
      position: relative;
      background-color: #fafafa;
      border: .1rem solid var(--color-border-grey);
      display: flex;
      align-items: center;
      .radio-btn {
        @include custom-input-style;
        @include checkbox-base-style;
        @include custom-radio-style;
        margin-right: 1rem;
        @include media-sm {
          margin-right: 2rem;
        }
        &:checked {

        }
      }
      &.-selected {
        border-color: var(--color-yellow-1);
        // border-width: .2rem;
        box-shadow: inset 0 0 0 .1rem var(--color-yellow-1);
        --color-background: var(--color-yellow-1);
        --color-foreground: var(--color-white);
        .radio-btn {
          border-color: inherit;
        }
        // background-color: #dedede;

  //       transform: rotate(-180deg);
  // background: #FAFAFA;
  // border: 1px solid #E8E8E8;
  // border-radius: 6px;
      }
    }
  }
  .multiple-choice-grid {
    display: grid;
    grid-gap: 2rem;
    @include media-sm {
      // grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .radio-btn {
      // appearance: none;
      // background-color: #fafafa;
      // position: absolute;
      // top: 0;
      // left: 0;
      // padding: .5rem;
      &:checked {
        // background-color: #dedede;
      }
    }
  }
}
</style>
