<template lang="html">
  <a :href="'#' + $route.path"
  class="component-image-link"
  @click="goToTroubleshootingArticle(article.id, $event)">
    <span class="item-number">{{ itemNumber }}</span>

    <div class="image-container">
      <image-component v-if="image" :url="image.url" :alt-attr="image.alt"></image-component>
    </div>
  </a>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'troubleshootingImageLink',
  props: {
    articleID: String,
    itemNumber: Number,
  },
  mixins: [TranslationMixin],
  computed: {
    article: function() {
      return this.$store.getters.getTroubleshootingByID(this.articleID)
    },
    image: function() {
      if (this.article && this.article.image && this.article.image.url) {
        return this.article.image
      }
      return false
    },
  },
  methods: {
    goToTroubleshootingArticle: function(id, event) {
      event.preventDefault();
      this.$emit('go-to-troubleshooting', id);
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
