<template lang="html">
  <main class="view parts">
    <transition name="slide-right">
      <div class="view-inner">

        <nav class="subnavbar">
          <router-link class="back-link" :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }">
            <icon graphic="chevron" class="-left"></icon>
            <span class="link-text">{{ _(activeVariant.title) | capitalise }}</span>
          </router-link>
        </nav>

        <header class="view-header">
          <h1 class="title">{{ _('part_plural') | capitalise }}</h1>

          <section class="breadcrumb">
            <div class="crumb">
              <router-link :to="{ name: 'Home', params: {} }">{{ _('home') | capitalise }}</router-link>
            </div>
            <span class="separator">/</span>
            <div class="crumb">
              <router-link :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }">{{ _(activeVariant.title) | capitalise }}</router-link>
            </div>
            <span class="separator">/</span>
            <div class="crumb">
              {{ _('part_plural') | capitalise }}
            </div>
          </section>
        </header>

        <parts :ids="activeVariant.parts"></parts>
      </div>

      <!-- <modal v-if="activePart" modalID="modalPart" :modalTitle="_(activePart.title)" modalClass="standard">
        <template v-slot:content>
          <p>{{ _(activePart.description) }}</p>
          {{ activePart }}
        </template>
      </modal> -->
    </transition>
  </main>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import ModalMixin from '@/mixins/modal.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'
import Parts from '@/components/Parts.vue'

export default {
  name: 'partsView',
  data: function() {
    return {
      activePartID: null,
    }
  },
  mixins: [TranslationMixin, ModalMixin, ActiveItemsMixin],
  components: {
    Parts
  },
  computed: {
    partsURL: function() {
      return '#/product/' + this.activeProduct.id + '/variant/' + this.activeVariant.id + '/parts/'
    },
  },
  methods: {
    getKey: function(item) {
      return item.type + '_' + item.id
    },
    openPartModal: function(id, event) {
      event.preventDefault();
      // if (event && event.preventDefault) {
      //
      // }
      // this.activePartID = id;
      this.$store.dispatch('setActivePart', id).then(() => {
        this.openModal('modalPart')
      })
      // this.openModal('modalPart')
    }
  }
}
</script>

<style lang="scss" >
.view.parts {
  --subnavbar-height: 4rem;
  .component-list {
    // @include media-sm {
    //   --gutter-width: 3rem;
    // }
    margin-left: var(--gutter-width);
    margin-right: var(--gutter-width);
  }
}
</style>
