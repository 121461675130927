<template>
  <section class="article-content">
    <template v-for="(block, index) in activeArticle.content">

      <dl class="content-block" :class="block.layout" v-if="block.layout === 'problem_solution'" :key="'block_' + index">

        <div class="problem">
          <dt class="key">{{ _('problem') | capitalise }}:</dt>
          <dd class="value">{{ _(block.problem) }}</dd>
        </div>

        <div class="solution">
          <dt class="key">{{ _('solution') | capitalise }}:</dt>
          <dd class="value">{{ _(block.solution) }}</dd>
        </div>

      </dl>

      <section class="content-block troubleshooting" :class="block.layout" v-if="block.layout === 'menu' && block.items && block.items.length" :key="'block_' + index">

        <div class="component-image-list" v-if="block.menu_layout_option === 'image_menu'">
          <tr-img-link :articleID="id"
          v-for="(id, index) in block.items"
          :key="'article_' + id"
          :item-number="index + 1"
          v-on:go-to-troubleshooting="goToTroubleshootingArticle"></tr-img-link>
        </div>

        <div class="component-list">
          <tr-link :articleID="id"
          v-for="(id, index) in block.items"
          :key="'article_' + id"
          :item-number="index + 1"
          :block-layout="block.menu_layout_option"
          v-on:go-to-troubleshooting="goToTroubleshootingArticle"></tr-link>
        </div>

      </section>

      <section class="content-block" :class="block.layout" v-if="block.layout === 'text'" :key="'block_' + index">
        {{ _(block.text_content) }}
      </section>

      <section class="content-block" :class="block.layout" v-if="block.layout === 'image'" :key="'block_' + index">
        <div class="image-container">
          <image-component v-if="block.image" :url="block.image.url" :alt-attr="block.image.alt"></image-component>
        </div>
      </section>

      <section class="content-block" :class="block.layout" v-if="block.layout === 'lessons'" :key="'block_' + index">
        <lesson-list-item class="lesson" v-for="(id, index) in block.lessons" :lessonID="id" :key="'lesson_' + id" :current="index + 1" :total="block.lessons.length"></lesson-list-item>
      </section>

    </template>
  </section>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import TroubleshootingLink from '@/components/fragments/troubleshootingLink.vue'
import TroubleshootingImageLink from '@/components/fragments/TroubleshootingImageLink.vue'
import LessonListItem from '@/components/fragments/LessonListItem.vue'

export default {
  name: 'TroubleshootingContent',
  mixins: [TranslationMixin],
  components: {
    'tr-link': TroubleshootingLink,
    'tr-img-link': TroubleshootingImageLink,
    LessonListItem
  },
  computed: {
    activeArticle: function() {
      return this.$store.getters.getActiveTroubleshooting
    }
  },
  methods: {
    goToTroubleshootingArticle: function(id) {
      console.log('troubleshooting content received', id);
      this.$emit('go-to-troubleshooting', id);
    }
  }
}
</script>

<style lang="scss" scoped>
.article-content {
  --border-radius: .4rem;

  > .content-block {
    &.lessons {
      display: grid;
      grid-gap: var(--gutter-width);
      @include media-sm {
        --gutter-width: 3rem;
      }
      > .lesson {
        background-color: var(--color-white);
        padding: var(--gutter-width) var(--gutter-width) 4rem;
        @include media-sm {
          padding: 4rem var(--gutter-width) 7rem;
        }
      }
    }

    &.image {
      display: flex;
      justify-content: flex-start;

      .image-container {
        // width: 100%;
        border: .1rem solid var(--color-blue-4);
        border-radius: var(--border-radius);
        background-color: var(--color-light-grey-3);
        overflow: hidden;
        display: flex;

        > img {
          max-width: 100%;
          max-height: calc(70vh - var(--navbar-height));
          // object-fit: cover;
        }
      }
    }

    &.troubleshooting {
      .component-image-list {
        --gutter-width: 1rem;
        display: grid;
        grid-gap: var(--gutter-width);
        margin-bottom: 3rem;

        @include media-sm {
          grid-template-columns: repeat(3, minmax(0, 1fr));
          margin-bottom: 5rem;
        }

        >>> .component-image-link {
          position: relative;

          .item-number {
            position: absolute;
            top: 2rem;
            left: 2rem;
            z-index: 2;
          }

          .image-container {
            position: relative;
            height: 0;
            width: 100%;
            z-index: 1;
            padding-bottom: 100%;
            border-radius: var(--border-radius);
            background-color: var(--color-light-grey-3);
            overflow: hidden;
            border: .1rem solid var(--color-blue-4);

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &:hover,
          &:active,
          &:focus {
            outline: none;

            .image-container {
              border: .1rem solid var(--color-yellow-1);
            }
          }
        }
      }

    }

    &.problem_solution {
      display: grid;
      grid-gap: 3rem;
      // margin: 0 var(--gutter-width);
      .problem {
        --gutter-width: 3rem;
        padding: 0 var(--gutter-width);
        // margin: 0 2rem;
      }
      .solution {
        background-color: var(--color-yellow-1);
        border-radius: .4rem;
        margin: 0 var(--gutter-width);
        padding: 3rem var(--gutter-width) 4rem;
        .key {
          color: var(--color-white);
        }
        .value {
          color: var(--color-blue-1);
        }
      }
    }
  }
}
</style>
