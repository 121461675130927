<template lang="html">
  <nav class="content-nav quiz-nav">
    <div class="nav-item">
      <button class="btn prev-button -has-icon"
      type="button"
      name="prevTask"
      v-if="prevID"
      @click="goToPrevTask">
        <icon graphic="arrow"></icon>
        <span>{{ _('previous') | capitalise }}</span>
      </button>
    </div>

    <div class="nav-item">
      <button class="btn finish-button"
      type="button"
      name="finishQuizButton"
      v-if="!nextID"
      @click="finishQuiz"
      :disabled="!answerGiven">
        <span>Finish quiz</span>
      </button>
    </div>

    <div class="nav-item">
      <button class="btn next-button -has-icon"
      type="button"
      name="nextTask"
      v-if="nextID"
      @click="goToNextTask"
      :disabled="!answerGiven">
        <span>{{ _('next') | capitalise }}</span>
        <icon graphic="arrow"></icon>
      </button>
    </div>
  </nav>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import CourseNavMixin from '@/mixins/courseNavigation.js'
import QuizCommonsMixin from '@/mixins/quizCommons.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'

export default {
  name: 'QuizNav',
  props: {

  },
  mixins: [TranslationMixin, CourseNavMixin, QuizCommonsMixin, ActiveItemsMixin],
  computed: {
    answer: function() {
      return this.activeTask.answer
    },
    answerGiven: function() {
      if (this.activeTask.answer !== null) {
        return true
      }
      return false
    },
    prevID: function() {
      if (!this.currentIndex) { // will either be 0 or null
        return false;
      } else if (this.taskIndex.length) {
        return this.taskIndex[this.currentIndex - 1];
      }
      return null;
    },
    nextID: function() {
      if (this.currentIndex >= this.taskIndex.length) {
        return false;
      } else if (this.currentIndex !== null && this.taskIndex.length) { // if currentIndex is not null, nor 0, and there is a taskIndex
        return this.taskIndex[this.currentIndex + 1]
      }
      return null;
    },
  },
  methods: {
    showTask: function(id) {
      this.$store.dispatch('setActiveTask', id);
    },
    goToNextTask: function() {
      this.navigateToTask(this.nextID, this.activeTask.id || null).then(() => {
        window.scrollTo(0, 0);
      })
    },
    goToPrevTask: function() {
      this.navigateToTask(this.prevID, this.activeTask.id || null).then(() => {
        window.scrollTo(0, 0);
      })
    },
    finishQuiz: function() {
      this.$store.dispatch('finishQuiz');
    }
  }
}
</script>

<style lang="css" scoped>
</style>
