<template lang="html">
  <nav class="content-nav quiz-nav">
    <div class="nav-item">
      <button class="btn review-btn -has-icon"
      type="button"
      name="showReviewButton"
      @click="reviewQuiz">
        <icon graphic="eye"></icon>
        <span>{{ _('review_quiz') | capitalise }}</span>
      </button>
    </div>

    <div class="nav-item">
      <button class="btn restart-button -has-icon"
      :class="{ '-blue': !quizProgress.passed }"
      type="button"
      name="restartQuizButton"
      @click="restartQuiz">
        <icon graphic="repeat"></icon>
        <span>{{ _('restart') | capitalise }}</span>
      </button>
    </div>

    <div class="nav-item">
      <router-link class="btn home-button -has-icon" :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }"
      :class="{ '-blue': quizProgress.passed }">
        <icon graphic="home"></icon>
        <span>{{ _('back_to') | capitalise }} {{ _(activeVariant.title) }}</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import CourseNavMixin from '@/mixins/courseNavigation.js'
import QuizCommonsMixin from '@/mixins/quizCommons.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'

export default {
  name: 'QuizSummaryNav',
  mixins: [TranslationMixin, CourseNavMixin, QuizCommonsMixin, ActiveItemsMixin],
  computed: {},
  methods: {
    restartQuiz: function() {
      // console.log('progress: ', this.quizProgress, 'task count: ', this.totalTaskCount);
      this.navigateToQuiz(this.activeQuiz.id).then(result => {
        this.$store.dispatch('setQuizResultsBool', false);
        this.navigateToTask(Object.keys(this.tasks)[0]);
      })
    },
    reviewQuiz: function() {
      console.log(this.tasks);
      this.$store.dispatch('setQuizReviewBool', true);
    },
  },
}
</script>

<style lang="scss" scoped>
.content-nav.quiz-nav {
  @include media-sm {
    display: flex;
    justify-content: center;
  }
  .nav-item {
    flex: 1 0 100%;
    width: auto;
    @include media-sm {
      flex: none;
      padding: 0.5rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    .btn {

    }
  }
}
</style>
