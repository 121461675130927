<template lang="html">
  <main class="view troubleshooting">
    <!-- <transition :name="subnavbarTransition">
      <header class="subnavbar">
          some troubleshooting stuff
      </header>
    </transition> -->

    <transition :name="articleTransitionName">
      <div class="view-inner" :key="articleKey">
        <nav class="subnavbar">
          <transition :name="articleTransitionName">

            <a class="back-link" :href="'#' + $route.path" @click="goBack(lastItem.id, $event)" v-if="lastItem">
              <icon graphic="chevron" class="-left"></icon>
              <span class="link-text">{{ _(lastItem.title) | capitalise }}</span>
            </a>

            <a class="back-link" :href="'#' + $route.path" @click="goBack(null, $event)" v-else-if="activeArticle">
              <icon graphic="chevron" class="-left"></icon>
              <span class="link-text">{{ _('troubleshooting') | capitalise }}</span>
            </a>

            <router-link class="back-link" :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }" v-else>
              <icon graphic="chevron" class="-left"></icon>
              <span class="link-text">{{ _(activeVariant.title) | capitalise }}</span>
            </router-link>

          </transition>
        </nav>

        <header class="view-header">
          <h1 class="title">{{ viewTitle | capitalise }}</h1>

          <section class="breadcrumb">
            <div class="crumb">
              <router-link :to="{ name: 'Home', params: {} }">{{ _('home') | capitalise }}</router-link>
            </div>
            <span class="separator">/</span>
            <div class="crumb">
              <router-link :to="{ name: 'Product', params: { productID: activeProduct.id } }">{{ _(activeProduct.title) | capitalise }}</router-link>
            </div>
            <span class="separator">/</span>
            <div class="crumb">
              <router-link :to="{ name: 'Variant', params: { productID: activeProduct.id, variantID: activeVariant.id } }">{{ _(activeVariant.title) | capitalise }}</router-link>
            </div>
            <span class="separator">/</span>

            <template v-if="activeArticle">
              <div class="crumb">
                <a :href="'#' + $route.path" @click="goBack(null, $event)">
                  {{ _('troubleshooting') | capitalise }}
                </a>
              </div>
              <span class="separator">/</span>
            </template>

            <template v-if="articleStack">
              <template v-for="item in articleStack">
                <div class="crumb articlestack" :key="'history_' + item.id">
                  <a :href="'#' + $route.path" @click="goBack(item.id, $event)">
                    {{ _(item.title) | capitalise }}
                  </a>
                </div>
                <span class="separator" :key="'separator_' + item.id">/</span>
              </template>
            </template>

            <div class="crumb" v-if="activeArticle">
              {{ _(activeArticle.title) | capitalise }}
            </div>
            <div class="crumb" v-else>
              {{ _('troubleshooting') | capitalise }}
            </div>
          </section>
        </header>

        <section class="article-content" v-if="!activeArticle">
          <section class="content-block text" v-if="activeVariant.troubleshooting.lead">
            {{ _(activeVariant.troubleshooting.lead) }}
          </section>

          <section class="content-block troubleshooting menu" v-if="articles">
            <div class="component-list">
              <tr-link :articleID="item.id"
              v-for="item in articles"
              :key="getKey(item)"
              v-on:go-to-troubleshooting="goToTroubleshootingArticle"></tr-link>
            </div>
          </section>
        </section>

        <troubleshooting-content v-if="activeArticle" v-on:go-to-troubleshooting="goToTroubleshootingArticle"></troubleshooting-content>

      </div>
    </transition>

  </main>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import ModalMixin from '@/mixins/modal.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'
// import Parts from '@/components/Parts.vue'
import TroubleshootingLink from '@/components/fragments/troubleshootingLink.vue'
import TroubleshootingContent from '@/components/troubleshootingContent.vue'
// import LessonListItem from '@/components/fragments/LessonListItem.vue'

export default {
  name: 'troubleshootingView',
  data: function() {
    return {
      // articleStack: [],
      articleTransitionName: 'slide-left',
      parentArticle: {
        id: 'troubleshootingArticle',
      },
      // activeArticle: null,
      lastArticle: null,
      nextArticle: null,
    }
  },
  mixins: [TranslationMixin, ModalMixin, ActiveItemsMixin],
  components: {
    'tr-link': TroubleshootingLink,
    // 'lesson-list-item': LessonListItem,
    'troubleshooting-content': TroubleshootingContent,
  },
  computed: {
    viewTitle: function() {
      if (this.activeArticle) {
        return this._(this.activeArticle.title);
      } else {
        return this._('troubleshooting');
      }
    },
    partsURL: function() {
      return '#/product/' + this.activeProduct.id + '/variant/' + this.activeVariant.id + '/parts/'
    },
    // activeProduct: function() {
    //   return this.$store.getters.getActiveProduct
    // },
    // activeVariant: function() {
    //   return this.$store.getters.getActiveVariant
    // },
    articleKey: function() {
      if (this.activeArticle) {
        return 'article_' + this.activeArticle.id;
      } else {
        return 'justTroubleshooting'
      }
    },
    activeArticle: function() {
      return this.$store.getters.getActiveTroubleshooting
    },
    historyStack: function() {
      return this.$store.getters.getTroubleshootingStack;
    },
    articleStack: function() {
      // var ids = this.$store.getters.getTroubleshootingStack;
      if (this.historyStack) {
        if (this.historyStack.length) {
          var articles = [];
          this.historyStack.forEach((id, i) => {
            var item = this.$store.getters.getTroubleshootingByID(id);
            if (item) {
              articles.push(item)
            }
          });
          return articles
        }
      }
      return false
    },
    lastItem: function() {
      if (this.articleStack.length) {
        return this.articleStack[this.articleStack.length - 1]
      } else {
        return false
      }
    },
    articles: function() {
      if (this.activeArticle) {
        return false
        // var lastItem = this.articleStack[this.articleStack.length - 1];
        // return lastItem;
        // sjekk om den har noen meny med barn, og returner dem, i så fall
        // if (lastItem.)
        // return false;
      } else if (this.activeVariant.troubleshooting && this.activeVariant.troubleshooting.items) {
        var aTheItems = [];
        this.activeVariant.troubleshooting.items.forEach((id, i) => {
          var oTheItem = this.$store.getters.getTroubleshootingByID(id);
          if (oTheItem) {
            aTheItems.push(oTheItem);
          }
        });
        return aTheItems
      }
      return false
    },
    subnavbarTransition: function() {
      if (this.routeName === 'Troubleshooting') {
        return 'slide-right';
      } else {
        return 'slide-left';
      }
    },
  },
  methods: {
    getKey: function(item) {
      return item.type + '_' + item.id
    },
    // goBack: function() {
    //   event.preventDefault();
    //   this.activeArticle = this.articleStack.pop();
    // },
    goToTroubleshootingArticle: function(id) {
      // event.preventDefault();
      this.articleTransitionName = 'slide-left';
      console.log(this.historyStack);
      var targetArticle = this.$store.getters.getTroubleshootingByID(id);
      if (targetArticle) {
        if (this.activeArticle) {
          var stack;
          if (this.historyStack) {
            stack = [...this.historyStack];
          } else {
            stack = [];
          }
          stack.push(this.activeArticle.id);
          this.$store.dispatch('updateTroubleshootingStack', stack);
        }
        this.$store.dispatch('setActiveTroubleshooting', id);
      }
    },
    goBack: function(id, event) {
      event.preventDefault();
      this.articleTransitionName = 'slide-right';
      var stack = [];
      // var lastID = this.activeArticle ? this.activeArticle.id : null;
      if (this.historyStack) {
        stack = [...this.historyStack];
        if (this.historyStack.length) {
          if (this.historyStack.indexOf(id) !== -1) {
            stack = this.historyStack.slice(0, this.historyStack.indexOf(id));
          }
        }
      }

      this.$store.dispatch('updateTroubleshootingStack', stack);
      this.$store.dispatch('setActiveTroubleshooting', id);
    },
  },
  mounted() {
    console.log(this.$root);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('updateTroubleshootingStack', []);
    this.$store.dispatch('setActiveTroubleshooting', null);
    next();
  }
}

</script>

<style lang="scss">
.view.troubleshooting {
  --subnavbar-height: 4rem;

  .article-content {
    display: grid;
    grid-gap: 3rem;

    @include media-sm {
      grid-gap: 5rem;
    }

    .content-block {
      &.text,
      &.image,
      &.menu {
        padding-right: var(--gutter-width);
        padding-left: var(--gutter-width);
      }

      &.text,
      &.image {
        @include media-sm {
          --gutter-width: 3rem;
        }
      }
    }
  }

  .article-content {
    & + .component-list {
      margin-top: 3rem;
      
      @include media-sm {
        margin-top: 5rem;
      }
    }
  }

  >>> .article-content {

  }
}
</style>
