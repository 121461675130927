<template lang="html">
  <nav class="course-menu" @click="openCourseMenu" :key="'course_menu_' + activeCourse.id">
    <div class="course-menu-inner">
      <h2>{{ _('course_content') | capitalise }}</h2>
      <div class="menu">
        <ul v-if="lessons">
          <li class="nav-item -lesson"
          v-for="lesson in lessons"
          :class="{ '-active': isActive(lesson), '-completed': getLessonStatus(lesson.id) }"
          :key="'lesson-nav-' + lesson.id">
          
            <a :href="courseURL + '/lesson'"
            :data-id="lesson.id"
            @click="goToLesson(lesson.id, $event)"
            @focus="openCourseMenu"
            :disabled="lessonLocked(lesson.id)">
              <icon v-if="getLessonStatus(lesson.id)" class="marker" graphic="checkmark"></icon>
              <i v-else class="icon marker"></i>
              <span class="link-text">{{ _(lesson.title) }}</span>
              <span v-if="getLessonStatus(lesson.id)" class="sr-only">Status: completed</span>
            </a>

          </li>

          <li class="nav-item -quiz"
          v-for="quiz in quizes"
          :class="{ '-active': isActive(quiz), '-completed': getQuizStatus(activeCourse.id, quiz.id) }"
          :key="'lesson-nav-' + quiz.id">

            <a :href="courseURL + '/quiz'"
            :data-id="quiz.id"
            @click="goToQuiz(quiz.id, $event)"
            @focus="openCourseMenu"
            :disabled="quizLocked(quiz.id)">
              <icon class="marker" graphic="checkmark" v-if="getQuizStatus(activeCourse.id, quiz.id)"></icon>
              <icon class="marker" graphic="quiz-marker" v-else></icon>
              <span class="link-text">{{ _(quiz.title) }}</span>
              <span v-if="getQuizStatus(activeCourse.id, quiz.id)" class="sr-only">Status: passed</span>
            </a>

          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
import CourseNavMixin from '@/mixins/courseNavigation.js'
import ActiveItemsMixin from '@/mixins/activeItems.js'

export default {
  name: 'CourseMenu',
  props: {
    lessonIDs: Array,
    quizIDs: Array,
  },
  mixins: [TranslationMixin, CourseNavMixin, ActiveItemsMixin],
  methods: {
    goToQuiz: function(id, event) {
      if (this.isCourseMenuOpen) {
        event.preventDefault();
        this.navigateToQuiz(id);
      }
    },
    goToLesson: function(id, event) {
      event.preventDefault();
      if (this.isCourseMenuOpen) {
        if (this.activeLesson) {
          this.navigateToLesson(id, this.activeLesson.id);
        } else {
          this.navigateToLesson(id);
        }
      }
    },
    getLessonStatus: function(id) {
      return this.$store.getters.getLessonStatus(this.activeCourse.id, id);
    },
    getQuizStatus: function(courseID, lessonID) {
      return this.$store.getters.getQuizStatus(courseID, lessonID);
    },
    quizLocked: function() {
      if (!this.lessonsDone) {
        return 'disabled'
      }
      return false
    },
    lessonLocked: function(id) {
      var lessonIndex = this.activeCourse.lessons.indexOf(id);
      if (lessonIndex === 0) {
        return false
      } else {
        if (!this.getLessonStatus(this.activeCourse.lessons[lessonIndex - 1]) || !this.isCourseMenuOpen) {
          return 'disabled'
        }
        return false
      }
    },
    isActive: function(item) {
      if (this.$route.name === 'Quiz' && this.activeQuiz) {
        if (item.id === this.activeQuiz.id) {
          return true
        } else {
          return false
        }
      } else if (this.$route.name === 'Lesson' && this.activeLesson) {
        if (item.id === this.activeLesson.id) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    openCourseMenu: function(e) {
      // if (e.target.classList.contains('course-menu')) {
      this.$store.dispatch('setCourseMenuOpen', true)
      // }
    },
  },
  computed: {
    courseURL: function() {
      return '#/product/' + this.activeProduct.id + '/variant/' + this.activeVariant.id + '/course/' + this.activeCourse.id
    },
    courseMode: function() {
      return this.$store.getters.getCourseMode
    },
    lessons: function() {
      var aTheLessons = [];
      if (this.lessonIDs) {
        this.lessonIDs.forEach((id, i) => {
          var oTheLesson = this.$store.getters.getLessonByID(id);
          if (oTheLesson && oTheLesson.id) {
            aTheLessons.push(oTheLesson);
          }
        });
      }
      if (aTheLessons.length) {
        return aTheLessons
      } else {
        return false;
      }
    },
    quizes: function() {
      var aTheQuizes = [];
      if (this.quizIDs) {
        this.quizIDs.forEach((id, i) => {
          aTheQuizes.push(this.$store.getters.getQuizByID(id));
        });
      }
      return aTheQuizes;
    }
  },
  mounted() {}
}
</script>

<style lang="scss">

.course-menu {
  --gutter-width: 1.5rem;
  padding: 0 var(--gutter-width) var(--gutter-width);
  overflow: hidden;

  .course-menu-inner {
    position: fixed;
    overflow-y: auto;
    height: calc(100vh - var(--navbar-height));
    width: 100%;
    top: var(--navbar-height);
    padding: 0 var(--gutter-width) var(--gutter-width);
    background-color: var(--color-white);

    @include media-sm {
      max-width: var(--course-menu-width);
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-row-gap: var(--gutter-width);
  }

  .nav-item {
    > a {
      display: flex;
      align-items: center;

      .link-text {
        opacity: 1;
        transition: opacity .3s ease 0s;
      }

      &[disabled] {
        .link-text {
          opacity: .3;
        }
      }

      .link-text {
        padding-top: .3em;
      }
    }

    @include lesson-marker;

    .marker {
      flex: none;
      margin: 0 var(--gutter-width) 0 0;
    }

    svg {
      z-index: 3;
    }

    &.-active {
      &.-lesson {
        &.-completed {
          .marker {
            &:before {
              background-color: transparent;
            }

            .svg-stroke {
              stroke: var(--color-yellow-1);
            }
          }
        }
      }

      &.-quiz {
        .marker {
          &:before {
            background-color: transparent;
          }

          .svg-stroke {
            stroke: var(--color-yellow-1);
          }
        }
      }
    }
  }
}
</style>
