<template>
  <article class="lesson">
    <header>
      <h2 class="title">
        <span class="lesson-count">{{ current }}/{{ total }}</span> <span>{{ _(lesson.title) | capitalise }}</span>
      </h2>
    </header>
    <section class="content-block" v-for="(block, index) in lesson.content" :key="'lessonBlock' + index">
      <video-component v-if="block.layout === 'video'"
      :url="block.url"
      :posterImage="block.poster"
      controls="controls">
      </video-component>
      <p v-if="block.layout === 'text'" v-html="_(block)"></p>
    </section>
  </article>
</template>

<script>
import TranslationMixin from '@/mixins/translation.js'
export default {
  name: 'LessonListItem',
  props: {
    lessonID: String,
    current: String,
    total: String,
  },
  mixins: [TranslationMixin],
  computed: {
    lesson: function() {
      return this.$store.getters.getLessonByID(this.lessonID)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
